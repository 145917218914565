const CACHE_NAME = 'LOCALHOST_VERSION_XXX_YYY';
const FEATURE_TOGGLES={};

// importScripts('https://storage.googleapis.com/workbox-cdn/releases/6.5.3/workbox-sw.js');
// import workbox from 'workbox-sw';
import {precacheAndRoute} from 'workbox-precaching';

// workbox.precaching.precacheAndRoute(self.ORIG__WB_MANIFEST);
precacheAndRoute(self.__WB_MANIFEST);
// const urlsToCache = URLS_TO_CACHE;

// const urlsToCache = [
//   '/',
//   `/css/about-${CACHE_NAME}.css`,
//   `/css/auth-${CACHE_NAME}.css`,
//   `/css/banner-${CACHE_NAME}.css`,
//   `/css/chat-${CACHE_NAME}.css`,
//   `/css/faqs-${CACHE_NAME}.css`,
//   `/css/footer-${CACHE_NAME}.css`,
//   `/css/header-${CACHE_NAME}.css`,
//   `/css/home-${CACHE_NAME}.css`,
//   `/css/lounge-${CACHE_NAME}.css`,
//   `/css/normalize-${CACHE_NAME}.css`,
//   `/css/notifications-${CACHE_NAME}.css`,
//   `/css/profile-${CACHE_NAME}.css`,
//   `/css/support-${CACHE_NAME}.css`,
//   `/css/termsOfUseAndPrivacy-${CACHE_NAME}.css`,
//   `/css/utils-${CACHE_NAME}.css`,
//   `/js/signupVerify-${CACHE_NAME}.js`,
//   `/js/banner-${CACHE_NAME}.js`,
//   `/js/chat-${CACHE_NAME}.js`,
//   `/js/clientUtilities-${CACHE_NAME}.js`,
//   `/js/emoji-${CACHE_NAME}.js`,
//   `/js/fb-${CACHE_NAME}.js`,
//   `/js/ggl-${CACHE_NAME}.js`,
//   `/js/index-${CACHE_NAME}.js`,
//   `/js/login-${CACHE_NAME}.js`,
//   `/js/loungeClientSide-${CACHE_NAME}.js`,
//   `/js/moment-${CACHE_NAME}.js`,
//   `/js/notifications-${CACHE_NAME}.js`,
//   `/js/profile-${CACHE_NAME}.js`,
//   // `/js/reset-${CACHE_NAME}.js`,
//   // `/js/signup-${CACHE_NAME}.js`,
//   `/about.html`,
//   `/error.html`,
//   `/faqs.html`,
//   `/index.html`,
//   `/login.html`,
//   `/maintenance.html`,
//   `/profile.html`,
//   `/sign-up.html`,
//   `/support.html`,
// ];

// console.log(
//   workbox.core,
//   workbox.routing,
//   workbox.precaching,
//   workbox.routing
// )
// self.addEventListener('install', function(event) {
//   // Perform install steps
//   event.waitUntil(caches.open(CACHE_NAME)
//       .then(function(cache) {
//         console.log('Opened cache');
//         return cache.addAll(urlsToCache);
//       }));
// });

const CONFIG = {
  NETWORK_FIRST: [
    '/',
    '/chat',
    '/lounge',
    '/auth',
  ],
};

let currentVersion;
let cache;

/**
* Function for grabbing current code version
* @return {Promise}
*/
function getCurrentVersion() {
  return new Promise(async (resolve, reject) => {
    currentVersion = await (await fetch(`/chat/version`)).text();
    caches.open(currentVersion).then(function(cacheObj) {
      cache = cacheObj;
    });
    resolve();
  });
}
getCurrentVersion();

/**
* @return {Object} push manager
*/
async function getPushManager() {
  const manager = await self.registration.pushManager.getSubscription();
  return manager;
}

const putInCache = async (request, response) => {
  if (typeof currentVersion == 'undefined') {
    getCurrentVersion();
    setTimeout(() => {
      putInCache(request, response);
    }, 50);
    return;
  }

  // const cache = await caches.open(currentVersion);
  // if(response.status !== 200){
  //   console.log('a non 200 response, nothing to cache here...')
  //   return;
  // }
  if (request.method != 'POST' && new URL(request.url).pathname !== '/chat/version' && response.status !== 204) {
    // console.log(`put in cache called with: ${request.url} (${request.method}) and on version ${currentVersion}`);
    await cache.put(request, response);
  }
};

const cacheFirst = async request => {
  const responseFromCache = await cache.match(request);
  if (responseFromCache) {
    return responseFromCache;
  }
  const responseFromNetwork = await fetch(request);
  putInCache(request, responseFromNetwork.clone());
  return responseFromNetwork;
};

const networkFirst = async request => {
  // console.log('Network first')
  let response;
  try {
    // response = await fetch(request);
    response = await Promise.race([
      fetch(request),
      new Promise((resolve, reject) => {
        setTimeout(() => {
          // console.log('We waited 10 seconds...')
          reject(new Error('Timeout'));
        }, 10000);
      }),
    ]);
    putInCache(request, response.clone());
    return response;
  } catch (error) {
    // console.log('woopsies we had an error!');
    // console.log(error);
    // console.log(error.message);
    if (error.message === 'Timeout' ||
      error.message === 'Network request failed') {
      // retry
      const responseFromCache = await caches.match(request);
      if (responseFromCache) {
        return responseFromCache;
      }

      // What to do when no network and no cache? Return shell.html if it was an html request?
      return response;
    }
    throw error; // rethrow other unexpected errors
  }
};

// TODO re-intruduce this for SPA like behaviors
if (typeof FEATURE_TOGGLES.SPASpoofing !== 'undefined' && FEATURE_TOGGLES.SPASpoofing == 'true') {
  self.addEventListener('fetch', function(event) {
    // if(typeof currentVersion === 'undefined' || typeof cache === 'undefined'){
    //   await getCurrentVersion();
    // }

    // if (this.registration.scope.indexOf('://localhost/') === -1) {
    // console.log(event.request);
    // console.log(event.request.url, ' vs ', event.request.referrer, (event.request.url.startsWith(event.request.referrer)));
    if (!event.request.url.startsWith(new URL(event.request.referrer).origin)) {
      // EXTERNAL REQUESTS

      // return fetch(event.request);
    // }else if(event.request.url.endsWith('.html')){
    //   // let segments = event.request.url.split('/');
    //   // let filename = segments[segments.length-1];
    //   // console.log(filename);
    //   return cache.match(`/${event.request.url.replace('.html','-fragment.html')}`);
    } else if (CONFIG.NETWORK_FIRST.find(endpoint => {
      const path = new URL(event.request.url).pathname;
      // console.log(path);
      if (path === '/') {
        return true;
      } else if (path.length > 1 && endpoint.length > 1) {
        return path.startsWith(endpoint);
      }
      return false;
    })) {
      event.respondWith(networkFirst(event.request));
      // return networkFirst(event.request);
      // return fetch(event.request);
    } else {
      event.respondWith(cacheFirst(event.request));
      // event.respondWith(cache.match(event.request)
      //     .then(function(response) {
      //       // Cache hit - return response
      //       if (response) {
      //         console.log('Responding with cached goodies for: ', event.request.url)
      //         return response;
      //       }
      //       // ok, put it in cache then;
      //       return fetch(event.request);
      //     }));
    }
    // }
  });
}

// self.addEventListener('activate', function(event) {
//   event.waitUntil(caches.keys().then(function(cacheNames) {
//     return Promise.all(cacheNames.filter(function(cacheName) {
//       return cacheName !== CACHE_NAME;
//     }).map(function(cacheName) {
//       return caches.delete(cacheName);
//     }));
//   }));
// });

// The following install/active nukes service workers and forces a reload
// uncomment when a service worker deploy has been FU BAR'd
self.addEventListener('install', () => {
  // Skip over the "waiting" lifecycle state, to ensure that our
  // new service worker is activated immediately, even if there's
  // another tab open controlled by our older service worker code.
  // self.skipWaiting();
});
self.addEventListener('activate', () => {
  // Optional: Get a list of all the current open windows/tabs under
  // our service worker's control, and force them to reload.
  // This can "unbreak" any open windows/tabs as soon as the new
  // service worker activates, rather than users having to manually reload.
  // self.clients.matchAll({
  //   type: 'window'
  // }).then(windowClients => {
  //   windowClients.forEach((windowClient) => {
  //     windowClient.navigate(windowClient.url);
  //   });
  // });
});

self.addEventListener('message', event => {
  if (event.data && event.data.type === 'SKIP_WAITING') {
    self.skipWaiting();
  }
});

self.addEventListener('push', async event => {
  // check if Talkdust is visible or not
  let isVisible = false;
  const windowClients = await clients.matchAll({
    type: 'window',
    includeUncontrolled: true,
  });

  for (let i = 0; i < windowClients.length; i++) {
    if (windowClients[i].visibilityState === 'visible') {
      isVisible = true;
    }
  }

  if (!isVisible) {
    // const title = 'Hello World';
    const payload = JSON.parse(event.data?.text()) ?? {title: 'no payload'};
    event.waitUntil(self.registration.showNotification(payload.title, payload.options));
  }
});

self.addEventListener('notificationclick', function(event) {
  event.notification.close();

  event.waitUntil(clients.matchAll({
    type: 'window',
  }).then(function(clientList) {
    for (let i = 0; i < clientList.length; i++) {
      const client = clientList[i];
      if (client.url == '/' && 'focus' in client) {return client.focus();}
    }
    if (clients.openWindow) {return clients.openWindow('/');}
  }));
});
